#daily_image {
    overflow-y: auto;
    max-height: 70vh;
    margin-top: 15vh;
    margin-left: 10%;
    margin-right:10%;
}

#daily_image img {
    max-width:100%;
    max-height: 50vh;
    min-height: 300px;
}